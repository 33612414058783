<template>
    <div class="container">
        <TitleBar></TitleBar>
        <div class="main">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import TitleBar from "./TitleBar";

export default {
    name: "Index",
    components: { TitleBar }
};
</script>

<style scoped>
.container{
    background-color: #f1f6ff;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}
.main {
    flex: auto;
    height: calc(100% - 65px);
}
#page {
    margin: 0 20px 20px;
    flex: auto;
    overflow: auto;
    padding: 5px;
    box-sizing: border-box;
}
</style>
